import React, { createContext, useState } from 'react'
import { Alert } from '@mui/material';

import Snackbar from '@mui/material/Snackbar';

export const SnackBarContext = createContext()

const AUTO_DISMISS = 4000

export function SnackBarProvider({ children }) {
    const [severity, setSeverity] = useState('success')
    const [text, setText] = useState("")
    const [openAlert, setOpenAlert] = React.useState(false);

    const handleAlertClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAlert(false);
    };

    const addAlert = (message, severity = 'success') => {
        setText(message);
        setSeverity(severity);
        setOpenAlert(true);
    }

    const value = { addAlert }

    return (
        <SnackBarContext.Provider value={value}>
            {children}

            <Snackbar
                anchorOrigin={{ vertical : 'bottom', horizontal : 'center' }}
                open={openAlert}
                autoHideDuration={AUTO_DISMISS}
                onClose={handleAlertClose}>
                <Alert onClose={handleAlertClose} severity={severity} sx={{ width: '100%' }}>
                    {text}
                </Alert>
            </Snackbar>
        </SnackBarContext.Provider>
    )
}