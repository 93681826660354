export const PROJECTRESOURCE = 'project';
export const USERADMINRESOURCE = 'useradmin';
export const SCHEMARESOURCE = 'schema';

export const CANREAD  = 'read';
export const CANCREATE  = 'create';
export const CANDELETE  = 'delete';
export const CANEDIT  = 'edit';
export const CANLIST  = 'list';
export const CANCREATEHOURSFOROTHERS  = 'createhoursother';
export const CANCHANGEITEGRATIONSETTINGS = 'createintegration';
