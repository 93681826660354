import axiosConfig from '../axiosConfig';

export default class TemplateApi {
    constructor() {
        this.endpoint = `/Template`;
    }

    async getTemplates(includeJson) {
        try {
            return axiosConfig.get(`${this.endpoint}?includeFormJson=${includeJson}`);
        }
        catch (error) {
            throw error;
        }
    }

    async getTemplateById(id) {
        try {
            return axiosConfig.get(`${this.endpoint}/${id}`);
        }
        catch (error) {
            throw error;
        }
    }

    async createTemplate(template) {
        try {
            return axiosConfig.post(this.endpoint, template);
        }
        catch (error) {
            throw error;
        }
    }

    async deleteTemplate(templateId, templateCategoryId) {
        try {
            return axiosConfig.delete(`${this.endpoint}/category/${templateCategoryId}/${templateId}`);
        }
        catch(error) {
            throw error;
        }
    }

    async updateTemplate(template) {
        try {
            return axiosConfig.put(`${this.endpoint}/${template.templateId}`, template);
        }
        catch(error) {
            throw error;
        }
    }

    async getTemplateCategories() {
        try {
            return axiosConfig.get(`${this.endpoint}/category`);
        }
        catch (error) {
            throw error;
        }
    }

    async createTemplateCategory(templateCategory) {
        try {
            return axiosConfig.post(`${this.endpoint}/category`, templateCategory);
        }
        catch (error) {
            throw error;
        }
    }

    async createTemplateResult(templateCategoryId, templateId, templateResult) {
        try {
            return axiosConfig.post(`${this.endpoint}/entry/${templateCategoryId}/${templateId}`, templateResult);
        }
        catch (error) {
            throw error;
        }
    }

    async deleteTemplateCategory(templateCategoryId) {
        try {
            return axiosConfig.delete(`${this.endpoint}/category/${templateCategoryId}`, {
                TemplateCategoryId : templateCategoryId
            });
        }
        catch(error) {
            throw error;
        }
    }

    async updateTemplateCategory(templateCategory) {
        try {
            return axiosConfig.put(`${this.endpoint}/category/${templateCategory.templateCategoryId}`, templateCategory);
        }
        catch(error) {
            throw error;
        }
    }

    async getTemplateResults(allTemplateResults) {
        try {
            return axiosConfig.get(`${this.endpoint}/entry?all=`+ allTemplateResults);
        }
        catch (error) {
            throw error;
        }
    }

    async promoteTemplate(templateId) {
        try {
            return axiosConfig.put(`${this.endpoint}/${templateId}/promote`);
        }
        catch (error) {
            throw error;
        }
    }
}