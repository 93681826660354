import React, { lazy } from 'react';
import { Routes, Route } from 'react-router-dom'
import { checkAccess } from './access';
import { Navigate } from 'react-router';
import { useSettings } from './views/CustomHooks/useSetting'
import { PROJECTRESOURCE, CANCREATE, CANEDIT, USERADMINRESOURCE, SCHEMARESOURCE, CANCREATEHOURSFOROTHERS, CANLIST } from './permissionConstants'
import { HOUR_SIMPLEREGISTRATION_SETTING } from './globalOptionSetting'
import {
  COMPANYINFO,
  ADDHOUR,
  REGISTERVIEW,
  REGISTERSUCCESSVIEW,
  ADDNEWUSER,
  ADDNEWPROJECT,
  EDITPROJECT,
  PROJECTDETAILS,
  CUSTOMERDETAILS,
  ACTIVITY,
  ADDNEWACTIVITY,
  EDITACTIVITY,
  ADDNEWCUSTOMER,
  EDITCUSTOMER,
  EDITUSER,
  ADDNEWOBSERVATION,
  EDITOBSERVATION,
  EQUIPMENTLIST,
  EQUIPMENTTYPELIST,
  CHECKLIST,
  CHECKLISTS,
  CHECKLISTVIEW,
  MEASURELISTS,
  MEASURELIST,
  MEASURELISTVIEW,
  INTEGRATIONLOG,
  INTEGRATION,
  INTEGRATIONCONNECT,
  DEVIATIONCODES,
  ATTESTHOURS,
  SALARYTYPE,
  GOODS,
  ADDGOODS,
  GOODSCONSUMPTION,
  ADDGOODSCONSUMPTION,
  PROFILE,
  PASSWORDRECOVERY,
  PASSWORDCHANGE,
  ADDHOURABSENCE,
  MYHOURS,
  MYHOURBANK,
  SIGNOUT,
  SIGNIN,
  DEPARTMENTS,
  MACHINES,
  ADDMACHINE,
  EDITMACHINE,
  NEWUSERCONFIRMATION,
  HOURINFO,
  ADDHOURRULE,
  EDITHOURRULE,
  ADDGROUP,
  EDITGROUP,
  ADDAPPROVALFLOW,
  EDITAPPROVALFLOW,
  TOKEN,
  DASHBOARD,
  DESIGNER,
  TEMPLATECATEGORY,
  ADDTEMPLATECATEGORY,
  EDITTEMPLATECATEGORY,
  TEMPLATE,
  ADDTEMPLATE,
  EDITTEMPLATE,
  PROJECTS,
  FORM,
  EDITFORM,
  FORMLISTALL,
  MYFORMS
} from './globals'
//import { TemplateCategory } from './views/Templates/TemplateCategory';

const MainLayout = lazy(() => import('./views/ReExport/Layouts/main'));
const MinimalLayout = lazy(() => import('./views/ReExport/Layouts/minimal'));

const SignInView = lazy(() => import('./views/ReExport/SignIn/signIn'));
const SignOutView = lazy(() => import('./views/ReExport/SignOut/signOut'));
const DashBoardView = lazy(() => import('./views/ReExport/Dashboard/dashboard'));

const NewUserConfirmationView = lazy(() => import('./views/ReExport/Users/newUserConfirmationView'));
const UserTabContainerView = lazy(() => import('./views/ReExport/Users/userTabContainer'));
const AddUserView = lazy(() => import('./views/ReExport/Users/addUser'));
const EditUserView = lazy(() => import('./views/ReExport/Users/editUser'));

const ProjectListTabContainerView = lazy(() => import('./views/ReExport/Projects/projectListTabContainer'));
const ProjectTabContainerView = lazy(() => import('./views/ReExport/Projects/projectTabContainer'));
const AddProjectView = lazy(() => import('./views/ReExport/Projects/addProject'));
const EditProjectView = lazy(() => import('./views/ReExport/Projects/editProject'));

const RolesListView = lazy(() => import('./views/ReExport/Roles/rolesList'));

const NewAccountView = lazy(() => import('./views/ReExport/Accounts/newAccount'));
const NewAccountSuccessView = lazy(() => import('./views/ReExport/Accounts/newAccountSuccess'));
const FirstTimeLoginWizardView = lazy(() => import('./views/ReExport/Wizard/firstTimeLoginWizard'));

const CompanyTabContainerView = lazy(() => import('./views/ReExport/Company/companyTabContainer'));
const HourView = lazy(() => import('./views/ReExport/Hours/hourList'));
const AttestHoursView = lazy(() => import('./views/ReExport/Hours/attestHour'));
const AddHourView = lazy(() => import('./views/ReExport/Hours/addHour'));

const ActivityListView = lazy(() => import('./views/ReExport/Activities/activityListTabContainer'));
const AddActivityView = lazy(() => import('./views/ReExport/Activities/addActivity'));
const EditActivityView = lazy(() => import('./views/ReExport/Activities/editActivity'));

const CustomersListView = lazy(() => import('./views/ReExport/Customers/customerList'));
const AddCustomerView = lazy(() => import('./views/ReExport/Customers/addCustomer'));
const EditCustomerView = lazy(() => import('./views/ReExport/Customers/editCustomer'));
const CustomerTabContainerView = lazy(() => import('./views/ReExport/Customers/customerTabContainer'));

const ObservationListView = lazy(() => import('./views/ReExport/Observations/observationList'));
const AddObservationView = lazy(() => import('./views/ReExport/Observations/addObservation'));
const EditObservationView = lazy(() => import('./views/ReExport/Observations/editObservation'));

const LocationView = lazy(() => import('./views/ReExport/Locations/locations'));

const EquipmentListView = lazy(() => import('./views/ReExport/Equipments/equipmentList'));
const EquipmentTypeListView = lazy(() => import('./views/ReExport/Equipments/equipmentTypeList'));

const CheckListsView = lazy(() => import('./views/ReExport/Templates/checkLists'));
const CheckListTemplateView = lazy(() => import('./views/ReExport/Templates/checkListTemplate'));
const CheckListTemplateDataInsertView = lazy(() => import('./views/ReExport/Templates/checkListTemplateDataInsert'));
const MeasureListOverviewView = lazy(() => import('./views/ReExport/Templates/measureListOverview'));
const MeasureTemplateView = lazy(() => import('./views/ReExport/Templates/measureTemplate'));
const MeasureListTemplateDataInsertView = lazy(() => import('./views/ReExport/Templates/measureListTemplateDataInsert'));

const IntegrationView = lazy(() => import('./views/ReExport/Integrations/integration'));
const IntegrationLogView = lazy(() => import('./views/ReExport/Integrations/integrationLogs'));
const IntegrationTabContainerView = lazy(() => import('./views/ReExport/Integrations/integrationTabContainer'));

const DeviationListView = lazy(() => import('./views/ReExport/Deviations/deviationList'));
const SalaryTabContainerView = lazy(() => import('./views/ReExport/Hours/salaryTabContainer'));
const GoodsTabContainerView = lazy(() => import('./views/ReExport/Goods/goodsTabContainer'));
const AddGoodsView = lazy(() => import('./views/ReExport/Goods/addGoods'));
const GoodsConsumptionView = lazy(() => import('./views/ReExport/Goods/goodsConsumption'));
const AddGoodsConsumptionView = lazy(() => import('./views/ReExport/Goods/goodsConsumptionAdd'));

const ProfileView = lazy(() => import('./views/ReExport/SignIn/profile'));
const PasswordRecoveryView = lazy(() => import('./views/ReExport/SignIn/passwordRecovery'));
const ChangePasswordView = lazy(() => import('./views/ReExport/SignIn/changePassword'));

const AddAbsenceView = lazy(() => import('./views/ReExport/Hours/addAbsence'));
const MyHoursView = lazy(() => import('./views/ReExport/Hours/myhours'));

const DepartmentsView = lazy(() => import('./views/ReExport/Departments/department'));

const MachinesListView = lazy(() => import('./views/ReExport/Machines/machinesList'));
const AddMachineView = lazy(() => import('./views/ReExport/Machines/addMachine'));
const EditMachineView = lazy(() => import('./views/ReExport/Machines/editMachine'));

const AddHourRuleView = lazy(() => import('./views/ReExport/Hours/addHourRule'));
const EditHourRuleView = lazy(() => import('./views/ReExport/Hours/editHourRule'));
const HourTabContainerView = lazy(() => import('./views/ReExport/Hours/hourTabContainer'));

const AddGroupView = lazy(() => import('./views/ReExport/Groups/addGroups'));
const EditGroupView = lazy(() => import('./views/ReExport/Groups/editGroups'));

const AddHourApprovalFlowView = lazy(() => import('./views/ReExport/Hours/addHourApprovalFlow'));
const EditHourApprovalFlowView = lazy(() => import('./views/ReExport/Hours/editHourApprovalFlow'));
const HourBankOverviewView = lazy(() => import('./views/ReExport/Hours/hourBankOverview'));

const DesignerView = lazy(() => import('./views/ReExport/Designer/designer'));

const TemplateCategoryView = lazy(() => import('./views/ReExport/Templates/templateCategories'));
const AddTemplateCategoryView = lazy(() => import('./views/ReExport/Templates/addTemplateCategories'));
const EditTemplateCategoryView = lazy(() => import('./views/ReExport/Templates/editTemplateCategories'));
const AddTemplateView = lazy(() => import('./views/ReExport/Templates/addTemplate'));
const EditTemplateView = lazy(() => import('./views/ReExport/Templates/editTemplate'));

const TemplateListsView = lazy(() => import('./views/ReExport/Templates/template'));

const FormListsView = lazy(() => import('./views/ReExport/Forms/formList'));
const FormEditView = lazy(() => import('./views/ReExport/Forms/editForm'));
const MyFormsView = lazy(() => import('./views/ReExport/Forms/myforms'));
const AllFormResultsView = lazy(() => import('./views/ReExport/Forms/allFormResults'));


const RoutesCustom = () => {

  const { enabled: hourRegistrationSimpleOption } = useSettings(HOUR_SIMPLEREGISTRATION_SETTING);

  const RouteIsAccessible = ({ resource: res, permission: perm, isPublic, layout: Layout, children }) => {
    if (isPublic) return <Layout>{children}</Layout>;

    if (!isAllowed(res, perm)) {
      return <Navigate to={SIGNIN} replace />;
    }

    return <Layout>{children}</Layout>;
  }

  const isAllowed = (res, perm) => {
    let token = localStorage.getItem(TOKEN);
    return (token && checkAccess(res, perm));
  }

  return (
    <Routes>

      <Route
        element={
          <RouteIsAccessible isPublic layout={MinimalLayout}>
            <SignInView />
          </RouteIsAccessible>
        }
        path={SIGNIN}
      />

      <Route
        element={
          <RouteIsAccessible isPublic layout={MinimalLayout}>
            <SignOutView />
          </RouteIsAccessible>}
        path={SIGNOUT}
      />

      <Route
        element={
          <RouteIsAccessible isPublic layout={MinimalLayout}>
            <NewAccountView />
          </RouteIsAccessible>}
        path={REGISTERVIEW}
      />

      <Route
        element={
          <RouteIsAccessible isPublic layout={MinimalLayout}>
            <NewAccountSuccessView />
          </RouteIsAccessible>}
        path={REGISTERSUCCESSVIEW}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <FirstTimeLoginWizardView />
          </RouteIsAccessible>}
        path="/initialsetup"
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <HourView />
          </RouteIsAccessible>}
        path="/hourlist"
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <MyHoursView />
          </RouteIsAccessible>}
        path={MYHOURS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <HourBankOverviewView />
          </RouteIsAccessible>}
        path={MYHOURBANK}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            {hourRegistrationSimpleOption ? <HourView /> : <AddHourView />}
          </RouteIsAccessible>}
        path={ADDHOUR}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddAbsenceView />
          </RouteIsAccessible>}
        path={ADDHOURABSENCE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout} resource={USERADMINRESOURCE} permissions={[CANCREATEHOURSFOROTHERS]}>
            <AttestHoursView />
          </RouteIsAccessible>}
        path={ATTESTHOURS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <ProjectListTabContainerView />
          </RouteIsAccessible>}
        path={PROJECTS}
      />


      <Route
        element={
          <RouteIsAccessible layout={MainLayout} resource={PROJECTRESOURCE} permissions={[CANCREATE]}>
            <AddProjectView />
          </RouteIsAccessible>}
        path={ADDNEWPROJECT}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout} resource={PROJECTRESOURCE} permissions={[CANEDIT]}>
            <EditProjectView />
          </RouteIsAccessible>}
        path={EDITPROJECT}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <ProjectTabContainerView />
          </RouteIsAccessible>}
        path={PROJECTDETAILS}
      />

      <Route
        element={
          isAllowed() ? <MainLayout><CompanyTabContainerView /></MainLayout> : <Navigate to={SIGNIN} replace />
        }
        path={COMPANYINFO}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <CustomersListView />
          </RouteIsAccessible>}
        path="/customers"
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <CustomerTabContainerView />
          </RouteIsAccessible>}
        path={CUSTOMERDETAILS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddCustomerView />
          </RouteIsAccessible>}
        path={ADDNEWCUSTOMER}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditCustomerView />
          </RouteIsAccessible>}
        path={EDITCUSTOMER}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout} resource={USERADMINRESOURCE} permissions={[CANCREATE]}>
            <UserTabContainerView />
          </RouteIsAccessible>}
        path="/users"
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddUserView />
          </RouteIsAccessible>}
        path={ADDNEWUSER}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditUserView />
          </RouteIsAccessible>}
        path={EDITUSER}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <RolesListView />
          </RouteIsAccessible>}
        path="/roles"
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <ActivityListView />
          </RouteIsAccessible>}
        path={ACTIVITY}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddActivityView />
          </RouteIsAccessible>}
        path={ADDNEWACTIVITY}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditActivityView />
          </RouteIsAccessible>}
        path={EDITACTIVITY}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <ObservationListView />
          </RouteIsAccessible>}
        path="/observations"
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddObservationView />
          </RouteIsAccessible>}
        path={ADDNEWOBSERVATION}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditObservationView />
          </RouteIsAccessible>}
        path={EDITOBSERVATION}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <LocationView />
          </RouteIsAccessible>}
        path="/locations"
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EquipmentListView />
          </RouteIsAccessible>}
        path={EQUIPMENTLIST}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EquipmentTypeListView />
          </RouteIsAccessible>}
        path={EQUIPMENTTYPELIST}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <CheckListsView />
          </RouteIsAccessible>}
        path={CHECKLISTS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <CheckListTemplateView />
          </RouteIsAccessible>}
        path={CHECKLIST}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <CheckListTemplateDataInsertView />
          </RouteIsAccessible>}
        path={CHECKLISTVIEW}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <MeasureListOverviewView />
          </RouteIsAccessible>}
        path={MEASURELISTS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <MeasureTemplateView />
          </RouteIsAccessible>}
        path={MEASURELIST}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <MeasureListTemplateDataInsertView />
          </RouteIsAccessible>}
        path={MEASURELISTVIEW}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <DashBoardView />
          </RouteIsAccessible>}
        path={DASHBOARD}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <IntegrationView />
          </RouteIsAccessible>}
        path={INTEGRATION}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <IntegrationLogView />
          </RouteIsAccessible>}
        path={INTEGRATIONLOG}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <IntegrationTabContainerView />
          </RouteIsAccessible>}
        path={INTEGRATIONCONNECT}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <DeviationListView />
          </RouteIsAccessible>}
        path={DEVIATIONCODES}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <SalaryTabContainerView />
          </RouteIsAccessible>}
        path={SALARYTYPE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <GoodsTabContainerView />
          </RouteIsAccessible>}
        path={GOODS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddGoodsView />
          </RouteIsAccessible>}
        path={ADDGOODS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <GoodsConsumptionView />
          </RouteIsAccessible>}
        path={GOODSCONSUMPTION}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddGoodsConsumptionView />
          </RouteIsAccessible>}
        path={ADDGOODSCONSUMPTION}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <ProfileView />
          </RouteIsAccessible>}
        path={PROFILE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <DepartmentsView />
          </RouteIsAccessible>}
        path={DEPARTMENTS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddHourApprovalFlowView />
          </RouteIsAccessible>}
        path={ADDAPPROVALFLOW}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditHourApprovalFlowView />
          </RouteIsAccessible>}
        path={EDITAPPROVALFLOW}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <HourTabContainerView />
          </RouteIsAccessible>}
        path={HOURINFO}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddHourRuleView />
          </RouteIsAccessible>}
        path={ADDHOURRULE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditHourRuleView />
          </RouteIsAccessible>}
        path={EDITHOURRULE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <MachinesListView />
          </RouteIsAccessible>}
        path={MACHINES}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddMachineView />
          </RouteIsAccessible>}
        path={ADDMACHINE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditMachineView />
          </RouteIsAccessible>}
        path={EDITMACHINE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddGroupView />
          </RouteIsAccessible>}
        path={ADDGROUP}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditGroupView />
          </RouteIsAccessible>}
        path={EDITGROUP}
      />

      <Route
        element={
          <RouteIsAccessible layout={MinimalLayout} isPublic>
            <PasswordRecoveryView />
          </RouteIsAccessible>}
        path={PASSWORDRECOVERY}
      />

      <Route
        element={
          <RouteIsAccessible layout={MinimalLayout} isPublic>
            <NewUserConfirmationView />
          </RouteIsAccessible>}
        path={NEWUSERCONFIRMATION}
      />

      <Route
        element={
          <RouteIsAccessible layout={MinimalLayout} isPublic>
            <ChangePasswordView />
          </RouteIsAccessible>}
        path={PASSWORDCHANGE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <DesignerView />
          </RouteIsAccessible>}
        path={DESIGNER}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <TemplateCategoryView />
          </RouteIsAccessible>}
        path={TEMPLATECATEGORY}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddTemplateCategoryView />
          </RouteIsAccessible>}
        path={ADDTEMPLATECATEGORY}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditTemplateCategoryView />
          </RouteIsAccessible>}
        path={EDITTEMPLATECATEGORY}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <TemplateListsView />
          </RouteIsAccessible>}
        path={TEMPLATE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <AddTemplateView />
          </RouteIsAccessible>}
        path={ADDTEMPLATE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <EditTemplateView />
          </RouteIsAccessible>}
        path={EDITTEMPLATE}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <FormListsView />
          </RouteIsAccessible>}
        path={FORM}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <FormEditView />
          </RouteIsAccessible>}
        path={EDITFORM}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout}>
            <MyFormsView />
          </RouteIsAccessible>}
        path={MYFORMS}
      />

      <Route
        element={
          <RouteIsAccessible layout={MainLayout} resource={SCHEMARESOURCE} permissions={[CANLIST]}>
            <AllFormResultsView />
          </RouteIsAccessible>}
        path={FORMLISTALL}
      />

      {/* <Redirect to="/not-found" /> */}
      <Route path="*" element={<Navigate to={SIGNIN} replace />} />

    </Routes>
  );
};

export default RoutesCustom;
