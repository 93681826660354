import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom'
import RoutesCustom from './Routes';

import theme from './theme';
import { ThemeProvider } from '@mui/system';
import { StyledEngineProvider } from '@mui/material/styles';
import { ConfirmProvider } from "material-ui-confirm";
import { SnackBarProvider } from './components/SnackBar/SnackBarProvider'

import { AbilityContext } from './config/Can'
import ability from './config/ability'

import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'

export default class App extends Component {
  render() {
    return (
      <DndProvider backend={HTML5Backend}>
      <AbilityContext.Provider value={ability}>
        <SnackBarProvider>
          <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
            <ConfirmProvider>
              <BrowserRouter future={{v7_startTransition: true, v7_relativeSplatPath: true }}>
                <RoutesCustom />
                </BrowserRouter>
             </ConfirmProvider>
            </ThemeProvider>
          </StyledEngineProvider>
        </SnackBarProvider>
      </AbilityContext.Provider>
      </DndProvider>
    );
  }
}
